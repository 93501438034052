import React, { Component } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

class Neuro extends Component {
  componentDidMount() {
    document.title = "Physiotherapy Services - Neurological Physiotherapy";
  }

  render() {
    return (
      <>
        <Header />

        <div id="sections">
          <section>
            <h2>Neurological Physiotherapy</h2>

            <p>
              Transforming Lives with individualised treatment plans to enhance
              movement, balance, and coordination.
            </p>
            <p>
              Cutting-edge techniques, based on the latest evidence to improve
              mobility, reduce pain, and boost independence. We are transparent
              about outcomes and compassionate in our approach.
            </p>
            <p>
              Conditions treated include but are not exclusively:
              <br />
              <br />
              <ul>
                <li>Multiple Sclerosis</li>
                <li>Brain Injury</li>
                <li>Spinal Cord Injury</li>
                <li>Cerebral Palsy</li>
                <li>Muscular Dystrophy</li>
                <li>Stroke</li>
                <li>Dementia</li>
              </ul>
            </p>
            <p>
              Discover how we can help improve your life and function—book a
              session today.
            </p>

            <a
              href="tel:01386240189"
              target="_blank"
              rel="noreferrer"
              className="button"
            >
              Book now
            </a>
            <p>
              <a className="back" href="/services">
                ◂ Back to Services
              </a>
            </p>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default Neuro;
