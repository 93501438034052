import React, { Component } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

class Hydrotherapy extends Component {
  componentDidMount() {
    document.title = "Physiotherapy Services - Hydrotherapy";
  }

  render() {
    return (
      <>
        <Header />

        <div id="sections">
          <section>
            <h2>Hydrotherapy / Aquatic Therapy</h2>

            <p>
              Aquatic therapy uses the buoyancy and resistance of water to offer
              a gentle yet effective way to rehabilitate the body. Hydrotherapy
              is in a warmer pool technically thermoneutral. Tailored
              hydro/aquatic therapy programs for individuals recovering from
              injuries, surgeries, or those with chronic pain conditions as well
              as learning disabilities and neurological patients. se of warm
              water to relax muscles, enhance circulation, and ease pain.
              <p>
                Being in the water has a calming effect, which can reduce
                anxiety and improve mental well-being, particularly important
                for neurological and learning disability clients.
              </p>
              <p>
                We tailor each session to fit the comfort levels and needs of
                our clients.
              </p>
              <p>
                Aquatic therapy is not only effective but enjoyable, especially
                for clients who may struggle with motivation in traditional
                land-based therapy.
              </p>
              Aquatic/hydrotherapy can help to:
            </p>

            <p>
              <h3>Reduced Joint Pressure</h3>
              The buoyancy of water supports the body, reducing stress on
              joints, making it ideal for people with arthritis, joint
              replacements, or mobility issues.
            </p>
            <p>
              <h3>Improved Strength and Flexibility</h3>
              Water resistance helps in building muscle strength and improving
              flexibility without risking injury.
            </p>
            <p>
              <h3>Enhanced Balance and Coordination</h3>
              The water environment provides a safe space to work on balance,
              especially for neurological and elderly patients.
            </p>
            <p>
              <h3>Pain Relief</h3>
              Warm water helps soothe sore muscles and joints, providing natural
              pain relief for a wide variety of conditions.
            </p>

            <p>
              Ready to experience the healing power of water? Contact us to
              schedule your session today!
            </p>

            <a
              href="tel:01386240189"
              target="_blank"
              rel="noreferrer"
              className="button"
            >
              Book now
            </a>
            <p>
              <a className="back" href="/services">
                ◂ Back to Services
              </a>
            </p>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default Hydrotherapy;
