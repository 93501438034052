import React, { Component } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

class Respiratory extends Component {
  componentDidMount() {
    document.title =
      "Physiotherapy Services - Respiratory / Lung Physiotherapy";
  }

  render() {
    return (
      <>
        <Header />

        <div id="sections">
          <section>
            <h2>Respiratory / Lung Physiotherapy</h2>

            <h3>Breathe Easy with Expert Respiratory Physiotherapy.</h3>

            <p>
              Seeking relief from persistent coughing, excessive phlegm, or
              shortness of breath?
              <p>Want to reduce the frequency of chest infections?</p>
              <p>
                Keen to improve your ability to walk despite your lung problems?
              </p>
            </p>
            <p>
              We thoroughly assess your condition and symptoms then deliver
              techniques for secretion clearance, breathlessness management and
              strengthening respiratory muscles and more.
            </p>
            <p>
              Conditions treated include but not exclusively:
              <br />
              <br />
              <ul>
                <li>Breathing Pattern Disorders</li>
                <li>COPD</li>
                <li>Pulmonary Fibrosis</li>
                <li>Long Covid</li>
                <li>Bronchiectasis</li>
              </ul>
              <br />
              <br />
            </p>
            <p>
              We provide clear communication about progress and realistic
              outcomes and are gentle and patient-centered. We aim for better
              lung function, less infections, breathing easier, reduced hospital
              admissions and improved quality of life. Visit us on{" "}
              <a
                href="https://www.instagram.com/lungphysio_uk/"
                rel="noreferrer"
                target="_blank"
              >
                Instagram
              </a>{" "}
              and on{" "}
              <a
                href="https://youtu.be/7grJuLV3JFI"
                rel="noreferrer"
                target="_blank"
              >
                YouTube
              </a>{" "}
              for information and videos.
            </p>

            <p>
              Breathe easier with our expert care—book your consultation today.
            </p>

            <a
              href="tel:01386240189"
              target="_blank"
              rel="noreferrer"
              className="button"
            >
              Book now
            </a>
            <p>
              <a className="back" href="/services">
                ◂ Back to Services
              </a>
            </p>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default Respiratory;
