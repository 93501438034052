import React, { Component } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

class D2A extends Component {
  componentDidMount() {
    document.title = "Physiotherapy Services - Discharge to Assess Beds";
  }

  render() {
    return (
      <>
        <Header />

        <div id="sections">
          <section>
            <h2>Discharge to Assess Beds</h2>

            <h3>
              Streamlining Patient Care through Expert Physiotherapy Support
            </h3>

            <p>
              We are committed to supporting the NHS and social care,
              particularly in managing patient flow and discharge processes and
              are experienced in working within multidisciplinary teams (MDT) to
              contribute to timely, effective patient discharges.
            </p>

            <p>
              Providing expert physiotherapy services for patients in discharge
              to assess beds, focusing on improving mobility and functional
              independence.
            </p>

            <p>
              Speeding Up the Discharge Process we facilitate faster discharge
              by conducting timely, thorough assessments that ensure patients
              are safe to return home or move to the next stage of their care
              pathway.
            </p>

            <p>
              Reducing Hospital Readmissions by proactive interventions helping
              reduce the risk of readmissions, ensuring patients receive ongoing
              care that addresses their rehabilitation needs in the community.
            </p>

            <p>
              Collaborative Care Planning with extensive experience working in
              MDTs, we ensure physiotherapy is seamlessly integrated into the
              overall patient care plan, collaborating with doctors, nurses,
              occupational therapists and social workers.
            </p>

            <p>
              Clear Communication and Professionalism with effective
              communication within the MDT enabling faster decision-making and
              coordinated care, improving the patient’s journey from hospital to
              home.
            </p>

            <p>
              Our Holistic Approach ensures that patients receive comprehensive
              care that addresses not just their physical needs but also their
              emotional, social, and environmental well-being.
            </p>

            <p>
              Supporting the NHS in Meeting Targets by improving mobility and
              independence, we help the NHS achieve smoother patient
              transitions, freeing up much-needed hospital beds for acute care.
            </p>

            <p>
              Improved Outcomes with early rehabilitation leading to better
              functional outcomes, reducing the length of stay in hospitals and
              promoting quicker recovery times.
            </p>

            <p>
              Efficient Discharge: Our physiotherapists play a key role in
              ensuring patients are safely discharged, promoting ongoing care
              and rehabilitation in the most appropriate setting.
            </p>

            <p>
              We deliver high-quality care in line with NHS standards, ensuring
              every patient receives the right level of support at the right
              time.
            </p>

            <p>
              Our physiotherapists take a compassionate approach, understanding
              the emotional toll of hospital stays and working to make the
              transition home as smooth as possible.
            </p>

            <p>
              Partner with us to streamline patient care and enhance discharge
              outcomes. Contact us to learn how we can support your discharge to
              assess services.
            </p>
            <a
              href="https://calendly.com/sian-mymp/discharge-to-assess-enquiry-1"
              target="_blank"
              rel="noreferrer"
              className="button"
            >
              Book a call
            </a>

            <p>
              <a className="back" href="/services">
                ◂ Back to Services
              </a>
            </p>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default D2A;
