import "./App.css";

import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Careers from "./pages/Careers";
import Services from "./pages/Services";
import Privacy from "./pages/Privacy";

import Pain from "./pages/services/Pain";
import HomeVisits from "./pages/services/HomeVisits";
import LearningDisabilities from "./pages/services/LearningDisabilities";
import Neuro from "./pages/services/Neuro";
import Hydrotherapy from "./pages/services/Hydrotherapy";
import Respiratory from "./pages/services/Respiratory";
import Training from "./pages/services/Training";
import D2A from "./pages/services/D2A";
import CareHomes from "./pages/services/CareHomes";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/careers" element={<Careers />} />
      <Route path="/services" element={<Services />} />
      <Route path="/privacy" element={<Privacy />} />

      <Route path="/services/pain-and-injury-clinic" element={<Pain />} />
      <Route path="/services/home-visits" element={<HomeVisits />} />
      <Route
        path="/services/learning-disabilities"
        element={<LearningDisabilities />}
      />
      <Route path="/services/neurological-conditions" element={<Neuro />} />
      <Route path="/services/aquatic-therapy" element={<Hydrotherapy />} />
      <Route
        path="/services/respiratory-and-lung-health"
        element={<Respiratory />}
      />
      <Route path="/services/training" element={<Training />} />
      <Route path="/services/discharge-to-assess-beds" element={<D2A />} />
      <Route path="/services/care-homes" element={<CareHomes />} />
    </Routes>
  );
}

export default App;
