import React, { Component } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

class Pain extends Component {
  componentDidMount() {
    document.title = "Physiotherapy Services - Pain & Injury";
  }

  render() {
    return (
      <>
        <Header />

        <div id="sections">
          <section>
            <h2>Pain &amp; Injury</h2>

            <p>
              Getting free from pain and restoring movement with musculoskeletal
              (MSK) physiotherapy We address pain, injuries and joint issues.
              Thorough assessments and bespoke rehabilitation programs including
              manual therapy, exercise prescriptions, massage and education.
            </p>
            <p>
              We aim to transform lives by enhancing recovery, reducing pain and
              preventing future injuries.
            </p>
            <p>
              We provide evidence-based treatments, patient-centered care with
              compassion at the core.
            </p>
            <p>
              Don't let pain slow you down - contact us to get moving again.
            </p>

            <p>
              <a
                href="https://midwinter-physiotherapy.uk1.cliniko.com/bookings?business_id=1546552212656755968"
                target="_blank"
                rel="noreferrer"
                className="button"
              >
                Book online
              </a>
            </p>

            <p>
              <a className="back" href="/services">
                ◂ Back to Services
              </a>
            </p>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default Pain;
