import React, { Component } from "react";
import headerImage from "../static/images/MidwinterPhysiotherapy-059-Edit.webp";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div id="call-banner">
          <a href="tel:01386240189">
            We are here for when you need us - 01386 240189
          </a>
        </div>
        <link
          rel="preload"
          fetchpriority="highest"
          as="image"
          href={headerImage}
        />
        <div id="title">
          <div style={{ flexGrow: 1, textAlign: "right" }}>
            <a href="/">
              <img
                src="/images/logo.png"
                fetchpriority="high"
                style={{ width: "64px", height: "64px" }}
                alt="Midwinter Physiotherapy"
              />
            </a>
          </div>
          <div style={{ flexGrow: 1 }}>
            <h1>
              <span className="midwinter">Midwinter</span>
              <br />
              Physiotherapy
            </h1>
          </div>
        </div>
        <nav>
          <div style={{ display: "flex", alignItems: "center" }}>
            <a href="/">Home</a>
            <a href="/about">About</a>
            <a href="/services">Services</a>
            <a href="/careers">Careers</a>
          </div>

          <div className="social">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/midwinterphysio"
                  title="Facebook"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/midwinterphysio"
                  title="Twitter"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/midwinterphysio/"
                  title="LinkedIn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
              </li>
              <li>
                <a
                  href="http://instagram.com/midwinterphysio"
                  title="Instagram"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://api.whatsapp.com/send?phone=447723541664"
                  title="WhatsApp"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-whatsapp"></i>
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <header></header>
        <section
          style={{
            textAlign: "center",
            borderBottom: "1px dotted #9259e6",
          }}
        >
          <a href="tel:01386240189">01386 240189</a>
          {" · "}
          <a href="mailto:info@midwinterphysiotherapy.co.uk">Email</a>
          {" · "}
          <a
            href="https://api.whatsapp.com/send?phone=447723541664"
            target="_blank"
            rel="noreferrer"
          >
            Whatsapp
          </a>
          {" · "}
          <a
            href="https://midwinter-physiotherapy.uk1.cliniko.com/bookings?business_id=1546552212656755968"
            target="_blank"
            rel="noreferrer"
          >
            Book online
          </a>
        </section>
      </>
    );
  }
}

export default Header;
