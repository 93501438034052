import React, { Component } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

class LearningDisabilities extends Component {
  componentDidMount() {
    document.title = "Physiotherapy Services - Learning Disabilities";
  }

  render() {
    return (
      <>
        <Header />

        <div id="sections">
          <section>
            <h2>Learning Disabilities</h2>

            <p>
              Supporting Health and Well-Being for Adults with Learning
              Disabilities.
            </p>
            <p>
              We provide personalised assessments and treatments focused on
              enhancing mobility and comfort. Our aim is to work closely with
              families and care providers to ensure the approach is holistic and
              compassionate whilst making it fun and engaging to get the most
              out of our sessions. We know we can help improve physical health,
              provide opportunities for more movement and prevent future health
              problems from being less mobile. We also work with other providers
              such as Herefordshire & Worcestershire ICB, Worcester University,{" "}
              <a
                href="https://myriadcentre.co.uk/hydrotherapy/"
                rel="noreferrer"
                target="_blank"
              >
                The Myriad Centre
              </a>{" "}
              and{" "}
              <a
                href="https://www.spectrumdays.co.uk/"
                rel="noreferrer"
                target="_blank"
              >
                Spectrum Days
              </a>{" "}
              providing specialist physiotherapy and hydrotherapy.
            </p>
            <p>
              We provide evidence-based treatments, patient-centered care with
              compassion at the core.
            </p>
            <p>
              If you are worried about mobility, body shape, lung health or
              struggling to keep your loved one moving then give us a call.
            </p>
            <p>
              <a
                href="tel:01386240189"
                target="_blank"
                rel="noreferrer"
                className="button"
              >
                Book now
              </a>
            </p>
            <p>
              <a className="back" href="/services">
                ◂ Back to Services
              </a>
            </p>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default LearningDisabilities;
