import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

class Services extends Component {
  componentDidMount() {
    document.title = "Physiotherapy Services";
  }

  render() {
    return (
      <>
        <Header />

        <div id="sections">
          <section>
            <ul class="services">
              <li>
                <a href="/services/pain-and-injury-clinic">
                  Pain &amp; Injury Clinic
                </a>
              </li>
              <li>
                <a href="/services/home-visits">Home Visits</a>
              </li>
              <li>
                <a href="/services/learning-disabilities">
                  Learning Disabilities
                </a>
              </li>
              <li>
                <a href="/services/neurological-conditions">
                  Neurological Conditions
                </a>
              </li>
              <li>
                <a href="/services/aquatic-therapy">Aquatic Therapy</a>
              </li>
              <li>
                <a href="/services/respiratory-and-lung-health">
                  Respiratory / Lung Health
                </a>
              </li>
              <li>
                <a href="/services/training">Training</a>
              </li>
              <li>
                <a href="/services/discharge-to-assess-beds">
                  Discharge to Access Beds
                </a>
              </li>
              <li>
                <a href="/services/care-homes">Care Homes</a>
              </li>
            </ul>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default Services;
