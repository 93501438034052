import React, { Component } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

class Training extends Component {
  componentDidMount() {
    document.title = "Physiotherapy Services - Training";
  }

  render() {
    return (
      <>
        <Header />

        <div id="sections">
          <section>
            <h2>Education &amp; Training</h2>

            <h3>
              Empowering Lives through Respiratory Care Training for Learning
              Disabilities
            </h3>

            <p>
              We offer tailored, specialist training for physiotherapists, care
              providers, families, and support workers.
            </p>
            <p>
              Practical skills to manage respiratory issues like breathlessness,
              secretion clearance, and chest infections.
            </p>

            <p>
              Reduce hospital admissions and improve quality of life for
              individuals with learning disabilities.
            </p>

            <p>Training built on the latest evidence-based practices.</p>

            <p>
              Engaging training methods to help participants retain essential
              skills.
            </p>

            <p>Ready to empower your care team? Contact us to enquire.</p>
            <a
              href="https://calendly.com/sian-mymp/respiratory-training-in-ld-enquiry"
              target="_blank"
              rel="noreferrer"
              class="button"
            >
              Book a call
            </a>

            <h3>
              Expert Talks for Care Homes and Independent Living Facilities
            </h3>

            <p>
              Are you frustrated by how sedentary your residents are?
              <p>Are you worried about your falls rates? </p>
              <p>Are you fed up of staff going off sick from injury? </p>
            </p>

            <p>
              Reducing falls can significantly improve residents' independence
              and well-being. We will increased confidence among staff and
              carers. Realistic advice and interventions that make a measurable
              difference. Interactive talks designed to engage participants, not
              just lecture them.
            </p>
            <p>
              We aim to increase knowledge and skills for care staff and older
              adults on how to prevent falls and stay safe and independent in
              later life.{" "}
            </p>
            <p>
              Comprehensive falls prevention talks with practical tips on manual
              handling, strengthening, balance and safe mobility.
            </p>
            <p>
              Help your residents stay on their feet—book a falls prevention
              talk today!
            </p>
            <a
              href="https://calendly.com/sian-mymp/15min"
              target="_blank"
              rel="noreferrer"
              className="button"
            >
              Book a call
            </a>
            <p>
              <a className="back" href="/services">
                ◂ Back to Services
              </a>
            </p>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default Training;
