import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Header />

        <div id="sections">
          <section>
            <p>
              When you send us an email or sign up to receive our newsletter
              etc., the personal information you submit may be stored on servers
              that are hosted in non-EU countries. Where this is the case, we
              will take steps to ensure that those providers use the necessary
              level of protection for your information and abide by strict
              agreements and measures set out by Midwinter Physiotherapy to
              protect your data and comply with the relevant data protection
              laws.
            </p>
          </section>

          <section>
            <h2>Consequences of not providing your data</h2>
            <p>
              You are not obligated to provide your personal information to
              Midwinter Physiotherapy however, certain information is required
              for us to provide our clinical services. We will not be able to
              offer our clinical services without it.
            </p>
          </section>
          <section>
            <h2>How long do we keep your data?</h2>
            <p>
              Midwinter Physiotherapy only ever retains personal information for
              as long as is necessary and we have strict review and retention
              policies in place to meet these obligations. We are required under
              UK tax law to keep your basic personal data (name, address,
              contact details) for a minimum of 6 years after which time it will
              be destroyed. We are required to keep your clinical records for a
              minimum of 8 years from the date of last treatment for adult
              records, and for children eight years after their 18 birthday or
              until 25 years of age.
            </p>
            <p>
              Where you have consented to us using your details for direct
              marketing, we will keep such data until you notify us otherwise
              and/or withdraw your consent.
            </p>
          </section>
          <section>
            <h2>Special categories data</h2>
            <p>
              Owing to the services and treatments that we offer, Midwinter
              Physiotherapy will need to process sensitive personal information
              (known as special category data) about you, to ensure safe and
              effective treatment can take place. Where we collect such
              information, we will only request and process the minimum
              necessary for the specified purpose and identify a compliant legal
              basis for doing so.
            </p>
            <p>
              Where we rely on your consent for processing special category
              data, we will obtain your explicit consent through a signature at
              your first consultation. You can modify or withdraw consent at any
              time, which we will act on immediately unless there is a
              legitimate or legal reason for not doing so.
            </p>
          </section>
          <section>
            <h2>Complaints Procedure</h2>
            <p>
              Midwinter Physiotherapy only processes your personal information
              in compliance with this privacy notice and in accordance with the
              relevant data protection laws. However, if you wish to raise a
              complaint regarding the processing of your personal data or are
              unsatisfied with how we have handled your information, you have
              the right to lodge a complaint with the supervisory authority.
            </p>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default Privacy;
