import React, { Component } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

class HomeVisits extends Component {
  componentDidMount() {
    document.title = "Physiotherapy Services - Home Visits";
  }

  render() {
    return (
      <>
        <Header />

        <div id="sections">
          <section>
            <h2>Home Visits</h2>
            <p>
              We support older adults in their own home or care home promoting
              mobility, balance, and strength whilst reducing frailty and falls
              risk. Problems we work with include:
            </p>
            <p></p>
            <p>
              <ul>
                <li>Mobility issues</li>
                <li>After a fall</li>
                <li>Post injuries or fractures</li>
                <li>After illness or hospital admission</li>
                <li>Post-surgery (including joint replacements)</li>
                <li>General age-related deterioration</li>
                <li>Progression of a known diagnosis</li>
                <li>Physiotherapy whilst in respite care</li>
              </ul>
            </p>
            <p>Treatments that we use include:</p>
            <p>
              <ul>
                <li>Focused interventions for fall prevention</li>
                <li>Post-surgery rehabilitation</li>
                <li>General mobility improvement.</li>
                <li>Strengthening work</li>
                <li>Regaining balance</li>
                <li>Gait re-education</li>
                <li>Postural-education</li>
                <li>Functional work</li>
                <li>Pain management</li>
                <li>Regaining confidence and independence</li>
                <li>Increasing exercise tolerance</li>
                <li>Massage &amp; stretching</li>
              </ul>
            </p>
            <p>
              This specialist physiotherapy helps maintain/increase
              independence, reduce pain and improve quality of life.
            </p>
            <p>
              Treating every elderly client with the care we would give our own
              family.
            </p>
            <p>
              Help your loved ones stay active and independent—schedule an
              appointment today!
            </p>
            <p>
              <a
                href="tel:01386240189"
                target="_blank"
                rel="noreferrer"
                className="button"
              >
                Book now
              </a>
            </p>
            <p>
              <a className="back" href="/services">
                ◂ Back to Services
              </a>
            </p>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default HomeVisits;
