import React, { Component } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

class CareHomes extends Component {
  componentDidMount() {
    document.title = "Physiotherapy Services - Care Homes";
  }

  render() {
    return (
      <>
        <Header />

        <div id="sections">
          <section>
            <h2>Care Homes</h2>

            <p>
              We can support your residents to get strong and stay healthy for
              their future, thus preventing falls and increased care burden. We
              can work alongside your staff to enhance their skills and promote
              a vibrant mobile home. Families prefer to choose care homes with
              physiotherapy provided and we are skilled at supporting CQC
              assessments to be OUTSTANDING.
            </p>
            <p>
              <ul>
                <li>
                  Carers of people with dementia often display poor health when
                  compared to their age-matched peers
                </li>
                <li>
                  One third of over 65s fall at least once a year, rising to 50%
                  for those over 80
                </li>
                <li>
                  750,000 people in the UK live with dementia and one-third in
                  care homes
                </li>
                <li>
                  The health and social care sector has one of the highest rates
                  of work-related musculoskeletal disorders in the UK
                </li>
                <li>An average of 17 days off work per employee</li>
                <li>
                  Manual handling is the cause of around 20% of all workplace
                  injuries in the UK
                </li>
              </ul>
            </p>
            <p>
              Call today to see how we can support your care home residents and
              staff.
            </p>
            <a
              href="https://calendly.com/sian-mymp/15min"
              target="_blank"
              rel="noreferrer"
              className="button"
            >
              Book a call
            </a>
            <p>
              <a className="back" href="/services">
                ◂ Back to Services
              </a>
            </p>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default CareHomes;
